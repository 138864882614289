import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthGuardService} from './guards/auth.guard.service';
import {SharedServiceService} from './services/shared-service.service';
import {NoAuthGuardService} from './guards/no-auth.guard.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    AuthGuardService,
    NoAuthGuardService,
    SharedServiceService,
  ]
})
export class CoreModule {
}
