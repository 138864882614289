import {environment} from '../../../environments/environment';

export const AppConstant = {
	agoraAppID: '68904093e3cc4cc696d77821e8b9570b',
	token: environment.authKey,
	apiUrl: environment.apiDomain,
	kundliUrl: environment.kundliApi,
	chatApi: environment.chatApi,
	astroMallApi: environment.astroMallApi,
	liveApi: environment.liveEventDomain,
	supportApiUrl: environment.apiDomainSupport,
	awsLink: 'https://astrotalk.s3.amazonaws.com/',
	ipAddress: 'https://api.ipify.org/?format=json',
	fileUploadApi: 'api/v1/horoscope/user/upload-files',

	astroSignUp: '',
	// astroLogin: 'consultant/api/v1/login',
	frontDomain: environment.frontDomain,
	userDomain: environment.userDomain,
	astroLogin: 'login/consultant',
	deviceInfoUpdate: 'consultant-device/create',
	astroGoogleLogin: 'login/signup/consultant/google/v3',
	forgotPassword: 'consultant/forgot/password',
	resetPassword: 'consultant/reset/password',
	generateOpt: 'consultantRegistration/mobile-otp-login',
	verifyOtp: 'consultantRegistration/mobile-otp-login/verify',
	firebaseVerifyOtp: 'consultantRegistration/firebase-login/verify',
	savePageTwo: 'consultantRegistration/savePageTwo',
	savePageThree: 'consultantRegistration/savePageThree',
	filterApi: 'filter/get/filter-type-value/active',
	fireBaseToken: 'consultant/update-gcm',
	fireBaseTokenApiForAdmin: 'admin/api/v1/set-webtoken',
	otpLogin4Digit: 'v2/login/consultant/mobile-otp-login',
	otpDetails: 'login/consultant/mobile-otp-login/verify',
	mobileLoginFirebase: 'login/consultant/mobile-firebase-login/verify',
	saveAlertLogs: 'alerts/logs-save',
	countryCodes: 'astrologer/payment/get/currency/country/for/foreign/payout',

	// live event apis
	eventList: 'live/event/v2/astrologer/list',
	goLive: 'live/event/v2/go/live',
	endLive: 'live/event/v2/end',
	scheduleEvent: 'live/event/create',
	updateSchedule: 'live/event/update',
	liveMessageHistory: 'live/event/v2/activity/consultant/history',
	liveLatestMessage: 'live/event/v2/activity/consultant',
	liveLatestMessagePrivate: 'live/event/v3/activity/consultant',
	muteLiveAudio: 'live/event/call/set/mute/status',
	muteLiveVideo: 'live/event/call/set/video/mute',
	liveCallStatus: 'live/event/call/get/status',
	liveActiveUsers: 'live/event/v2/current/subscribers',
	liveSubscribers: 'live/event/subscriber/count',
	liveDonations: 'live/event/v2/activity/donations/consultant',
	livePinnedMessage: 'live/event/pinnedmessage/get',
	livePinnedMessageSet: 'live/event/pinnedmessage/put',
	liveCallPrice: 'live/event/call/get/price',
	liveCallStatusUpdate: 'live/event/call/update/call/status',
	waitListLive: 'live/event/call/get/status/v4',
	acceptLiveCall: 'live/event/call/join/consultant',
	rejectLiveCall: 'live/event/call/reject',
	endLiveCall: 'live/event/call/end',
	blockUsers: 'live/event/block/list',
	blockToUser: 'live/event/block/user',
	queToken: 'queue-token/get/liveEvent/consultant',
	takeBreakFor5Min: 'live/event/consultant/set/break',
	myFollowersApi: 'live/event/get/follow/list/consultant',
	loyalClubMembershipApi: 'membership/subscription/consultant',


	// History APIs
	offerHistory: 'admin/offer/get',
	reportList: 'consultant/get/report',
	submitReportApi: 'report_answer',
	saveReportApi: 'report/save',
	astroMallHistory: 'product-type/order/get',
	waitList: 'call-chat/queue/get/queue',
	startFromWaitList: 'call-chat/queue/startOffline',
	endOfflineCall: 'calling/call-fix',
	getRemedies: 'prescription/get/byConsultantId',
	singleRemedyHistory: 'prescription/getPrescriptionList',
	closeAstromallOrder: 'astromall/close/orders',

	// wallet appis
	walletHistory: 'consultant/wallet/get/transaction',
	pendingPayment: 'get/credit/assign/money',

	// group chat
	getGroupChatHistory: 'group/chat/get-history',
	groupSendMessage: 'group/chat/send-message',
	groupGetLastMessage: 'group/chat/get-latest-messages',
	groupChatDelete: 'group/chat/delete-msg',

	// fraud detection api
	antiFraudFlagsAPI: 'chat/message/v2/get-flag-value',
	fraudFlagsAPI: 'chat/message/get-flag-value',
	updatePinnedMessageApi: 'chat-assistance-manager/pinned/message',

	// support chat
	supportChatTicketListApi: 'admin-consultant-chat/v2/get-chat-list',
	supportChatCreateTicketApi: 'admin-consultant-chat/v3/create-chat',
	supportChatAutomatedMessages: 'support/api/v1/automated-messages',
	supportChatMessageHistory: 'admin-consultant-chat/get-chat-history',
	supportChatSendApi: 'admin-consultant-chat/send-message',
	supportChatLatLastMessageApi: 'admin-consultant-chat/get-latest-message',
	supportTicketReopen: 'chat/api/reopen/consultant/ticket',
	supportChatGetRatting: 'chat/rating/review/v1/get',
	supportChatReview: 'chat/rating/review/v1/add',
	supportChatDelete: 'admin-consultant-chat/deleteChatMsgV2',
	rmTicket: 'admin-consultant-chat/get/rmTicket',
	getRmTicketDataApi: 'admin-consultant-chat/get/rmTicket/id',
	ticketStatus: 'chat/api/v2/get-open-chat-status',
	restartChatNow: 'admin-consultant-chat/restart-chat',

	supportChatAllTicketDelete: 'chat/api/delete-all-user-chats',
	supportChatTicketDelete: 'chat/api/v2/delete-chat',
	supportChatActiveId: 'user/get/support/chatId',
	likeMessage: 'chat/api/like/message',

	getAutoMsg: 'support/api/v1/consultant/automated-messages',
	saveAndUpdateAutoMsg: 'support/api/v1/consultant/save-message',
	deleteAutoMsg: 'support/api/v1/consultant/delete-message',
	completedOrders: 'chat/order/get-completed-orders',

	// ticket api
	supportTicketList: 'consultant/warning/get',

	// Assistant Chat
	assistantChatCallHistory: 'chat/order/get-list',
	checkAssistant: 'chat-assistance-manager/is/paid/user',
	assistantList: 'chat-assistance-manager/admin/chat/list',
	assistantMessageHistory: 'chat-assistance-manager/get-chat-history',
	assistantPinedMessage: 'chat-assistance-manager/pinned/message',
	assistantLastMessage: 'chat-assistance-manager/get-latest-message',
	assistantSendMessage: 'chat-assistance-manager/send/message',
	assistantMute: 'chat-assistance-manager/mute/chat',
	checkBlockAssistant: 'chat-assistance-manager/get/block/assistance',
	blockAssistant: 'chat-assistance-manager/block/assistance',
	deleteAssistantChat: 'chat-assistance-manager/delete/message',

	// chat APIs
	chatHistory: 'chat/order/get-list',
	chatAccept: 'chat/order/accept/byConsultant',
	chatReject: 'chat/order/canceled/byConsultant',
	getChatMsg: 'chat/message/get-chat-history',
	sendChatMsg: 'chat/message/send-message',
	lastMessageApi: 'chat/message/v2/get-latest-message',
	deleteMessageApi: 'chat/message/deleteChatMsg',
	chatEndedApi: 'chat/order/completed',
	chatStatusApi: 'chat/order/get/status',

	videoCallStatusApi: 'calling/agora-voip/get/call/status',
	videoAcceptApi: 'calling/agora-voip/join/consultant',
	videoRejectApi: 'calling/agora-voip/reject/call',
	videoMuteApi: 'calling/agora-voip/mute-video',
	audioMuteApi: 'calling/agora-voip/mute',
	videoCallEndApi: 'calling/agora-voip/call/end',

	saveNotes: 'notes/consultant/save',
	getNotes: 'notes/consultant/get',
	deleteRemedy: 'prescription/delete',

	// In-mail
	inMailList: 'consultant/warning/get',
	inMailDetails: 'consultant/warning/reply/get',
	replayInMail: 'consultant/warning/reply/add',

	// call APIs
	callHistory: 'calling/order/get',
	callHistoryDetails: 'calling/order/getById',
	callChatRefund: 'total/refund',

	// block apis
	checkBlockStatus: 'admin-consultant-chat/user-block-status',
	createBlockTicket: 'admin-consultant-chat/v3/create-chat',
	sendBlockMessage: 'admin-consultant-chat/send-message',
	checkConsultantEarningApi: 'consultant/earning/amount/for-user',
	blockUserApi: 'block/user',

	// profile Apis
	profileUpdate: 'consultant/update/details',
	profileGet: 'consultant/details',
	mobileNumGet: 'consultant/get/mobile-no',
	updateMobileNumber: 'consultant/update/mobile-no',
	savedNumberApi: 'calling/get/call/service/contacts',
	faith: 'consultant/get/all/religion/v2',
	getCities: 'cities/allcountries/autocomplete',
	getSecondPlace: 'autocomplete.php',
	addNewCity: 'cities/allcountries/add',

	// training videos apis
	videoCategories: 'reels/get-all-category',
	getVideos: 'reels/get-all-reels/with-category',
	getVideosByName: 'reels/searchByName',
	getReelWatchApi: 'reels/update-watched-reels',

	// astromall
	getProducts: 'product-type/get-all/active-consultant',
	setProductStatus: 'product-type/update/local-online-status',
	getFestivalsBannersApi: 'image/get-for-consultant',

	// dashboard Apis
	problemAreaCategories: 'consultant/category/get',
	updateProblemAreas: 'consultant/category/update',

	// dashboard Status Apis
	astrologerAvailableStatus: 'consultant/get/availablilty/by/consultant',
	changeAstrologerStatus: 'consultant/set/availablilty/by/consultant',
	nextOnlineDateAndTime: 'consultant/set/when/online/by/consultant',

	weeklyTimeTable: 'timetable/get/weekly',
	weeklyTimeTableUpdate: 'timetable/addupdate',


	getOfferStatus: 'offer/v3/consultant/status',
	changeOfferStatus: 'admin/offer/activate/v3',
	getOfferStatusList: 'admin/offer/activate',
	getFixedSession: 'consultant/fixed-sessions/consultant/active',
	getFixedSessionStatus: 'consultant/fixed-sessions/consultant/is-active',
	setFixedSessionStatus: 'consultant/fixed-sessions/consultant/create',
	information: 'get/static/value',
	activatedCall: 'calling/agora-voip/rejoin',
	activatedChat: 'chat/order/get/user/chat-status/byConsultant',

	// dashboard boost Apis
	boostInfo: 'consultant/boost/get-info',
	boostHistory: 'consultant/boost/get-boost',
	consultantBoostStatus2: 'consultant/get/schedule/boost/info',
	boostAvailability: 'consultant/check/schedule/boost/availability',
	updateBoostStatus: 'consultant/update/schedule/boost',
	checkRelationshipMangerApi: 'consultant/check/rm',
	astrologerPerformance: 'consultant/get/performance',
	astrologerEarnings: 'consultant/earning/get/rank',
	pastAvailabilityLogs: 'consultant/rating/get-past-availability-logs',
	noticeBoard: 'consultant/notice-board/get',
	allOfflineReasons: 'offline/message/get/all',
	tokenExpired: 'isTokenExpired/consultant',

	// dashboard feedback api
	ceoFeedback: 'managementFeedback/add',
	referral: 'consultant-referral/getByOldConsultantId',
	postReferral: 'consultant-referral/create',
	todayAvail: 'consultant/rating/refresh',


	// emergency status
	setEmergencyStatusCall: 'consultant/set/emergency',
	setEmergencyStatusChat: 'consultant/set/emergency/chat',
	getEmergencyStatusCallAndChat: 'consultant/get/emergency/status',


	// settings
	earning: 'consultant/earning/get',
	addRequestedPrice: 'astrologer/price/suggestion/add',
	requestedPriceList: 'astrologer/price/suggestion/get',
	getEarlierAndCurrentPriceFinal: 'astrologer/price/suggestion/price/prediction',
	earlierAndCurrentPrice: 'astrologer/price/suggestion/price/details',
	bankDetailList: 'astrologer/payment/get',
	bankDetailAdd: 'astrologer/payment/add',
	form16: 'consultant/get/tax-files/v2',
	logoutOtherDevices: 'login/log-out/from-other-devices',

	// remedies apis
	astromallCategoryList: 'astromall/get/product-type/for/consultant',
	astromallProductList: 'product-type/product/get',
	astrologerList: 'product-type/product/consultant/get',
	suggestRemedy: 'prescription/create/v2',
	productSearch: 'product-type/product/search/global',

	// galleryImages Api
	galleryImages: 'consultant-gallery/get/consultant-images/consultant',
	uploadGalleryImages: 'consultant-gallery/create',
	deleteImage: 'consultant-gallery/setInactive',
	showImage: 'consultant-gallery/setIsToShow',

	// Reviews
	reviewHistory: 'rating-review/getByConsulantId',
	pinnedReviews: 'rating-review/pinned/getByConsultantId',
	pinReview: 'rating-review/update/pinned',
	missedSession: 'consultant/get/missed/count',
	deleteReplayApi: 'rating-review/delete/rating',
	replayReviewApi: 'api/v1/flag/review/reply',
	activeReview: 'api/v1/flag/review/activeByConsultant',
	flagReviewTagApi: 'api/v1/flag/tag',
	flagReviewApi: 'api/v1/flag/review',
	// -------------------warnings---------------------------------//
	warningLists: 'consultant/mandatory-warning/get/warning-list',
	warningDetails: 'consultant/mandatory-warning/get/warning-details/id',
	warningStatus: 'consultant/mandatory-warning/status',
	loyaltyMembership: 'membership/subscription/get-visibility-loyal',
	updateMembershipStatus: 'membership/subscription/update-visibility-loyal',
	setPoForeign: 'consultant/update/po-local',

	// interview apis
	interviewLog: 'consultant/interview/get',
	updateFeedbackStatus: 'consultant/interview/update/status',
	waitListData: 'consultant/interview/get/userIds',
	interviewList: 'queue-token/get/by/consultantId',
	getAdminID: 'consultant/interview/get/admin/token',
	astrologerDetails: 'consultant/registration/get',
	callToNewAstrologer: 'consultantRegistration/initiate-call',
	showSnapshot: 'v2/admin/hiring/dashboard/interview/snapshot',
	durationManually: 'consultant/interview/update/duration',
	interviewStatus: 'consultant/interview/get/consultant-temp-id',
	langNote: 'consultant/interview/get/regional/language',
	// chatting with astrologer
	chatInterviewHistory: 'temp/admin-consultant-chat/get-chat-history',
	latestInterviewMessage: 'temp/admin-consultant-chat/get-latest-message',
	sendInterviewMessageApi: 'temp/admin-consultant-chat/send-message',
	ticketToChatWithAstrologer: 'temp/admin-consultant-chat/get-ticket',
	ticketWaitlist: 'consultant/interview/getWt',
	unholdWaiting: 'consultant/interview/update/un-hold/status',

	// kundli apis while live event
	kundliList: 'share/kundli/get',
	kundliFromList: 'birthdetail/get',

	//location
	sendLocation: 'consultant/add/event-location',
	copyMsg: 'chat/message/isCopied',
	getOtpConsultant: 'update/mobile/consultant/send-otp',
	verifyOtpConsultant: 'update/mobile/consultant/verify-otp/v2',
	mobileFirebaseVerifyNumber: 'update/mobile/consultant/mobile-firebase-login/verify-otp',

	// live-room-apis
	joinRoom: 'hiring/interview/join/live-room',
	perSecGetJoiningUsers: 'hiring/interview/get/live-room/session/candidate/status',
	liveRoomSessionStatus: 'hiring/interview/get/live-room/session/candidate/status',
	acceptRejectCall: 'hiring/interview/update/candidate/status',
	endCallUser: 'hiring/interview/session/live-room/end',
	startStopRecord: 'hiring/interview/start-end/live-room/recording',
	createAsAdmin: '/hiring/interview/assign/guest-admin',
	sendMessageInterview: '/hiring/interview/add/live-room/session/message',
	getMessageInterview: '/hiring/interview/get/live-room/session/messages-list',
	startScreenSharing: '/hiring/interview/update/screen-share/status',

	// payroll users
	shiftTiming: 'consultant/payroll/get/shift/time'
};

