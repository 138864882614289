import {Injectable} from '@angular/core';
import {AppConstant} from './constant';
import {HttpWrapperService} from './http-wrapper.service';

@Injectable({
	providedIn: 'root'
})
export class AuthenticateService {

	private ipAddressApi = AppConstant.ipAddress;
	private checkRelationshipMangerApi = AppConstant.apiUrl + AppConstant.checkRelationshipMangerApi;
	private langNote = AppConstant.apiUrl + AppConstant.langNote;

	constructor(private http: HttpWrapperService) {
	}

	/*public timeZone = (params) => {
		return this.http.get(this.timeZoneApi, params, true);
	}*/

	/*public login = (body) => {
		return this.http.post(this.loginApi, body);
	}*/

	/*public forgot = (body) => {
		return this.http.post(this.forgotApi, body);
	}*/

	public ipAddress = () => {
		return this.http.get(this.ipAddressApi);
	}

	checkRelationshipManger = (params): any => {
		return this.http.get(this.checkRelationshipMangerApi, params, true);
	}

	getLanguageNote = (params): any => {
		return this.http.get(this.langNote, params, true);
	}

}
