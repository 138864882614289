import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {CommonService} from './common.service';
import {AppConstant} from './constant';
import {throwError} from "rxjs";
import {tap} from "rxjs/operators";

class RequestOptions {
  constructor(param: { headers: any; withCredentials: boolean }) {
  }

}

@Injectable()
export class HttpWrapperService {
  token: string = null;
  astroData: any = [];
  astroId: string = null;

  constructor(private http: HttpClient, private common: CommonService) {}

	logError(error: HttpErrorResponse) {
		if (error.status == 401) {
			if (this.common.checkDeviceIsBrowser()) {
				this.common.logout();
				return false;
			}
		}
		return throwError('error your token is expired');
	}
	log(data) {
		return data;
	}

  public get(url, params = null, setHeader = false) {
    this.astroData = this.common.getAstroData();
    this.token = this.common.getToken();

    let header = {};
    if (setHeader && this.token) {
      header = { headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', 'Bearer ' + (this.token).toString())
        .set('id', (this.astroData ? this.astroData.id : '').toString())
      };
    }
    if (params) {
      const param = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      url = url + '?' + param;
    }
    return this.http.get(url, header).pipe(
			tap( // Log the result or error
				{
					next: (data) => this.log(data),
					error: (error) => this.logError(error)
				}
			)
		);
  }

  public getWithCandidateID(url, params = null, setHeader = false, id) {
    this.astroData = this.common.getAstroData();
    this.token = this.common.getToken();

    let header = {};
    if (setHeader && id) {
      header = { headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('id', (id ? id : '').toString())
      };
    }
    if (params) {
      const param = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      url = url + '?' + param;
    }
    return this.http.get(url, header).pipe(
			tap( // Log the result or error
				{
					next: (data) => this.log(data),
					error: (error) => this.logError(error)
				}
			)
		);
  }

  public getFromAdmin(url, params = null, setHeader = false, token, adminID) {
    this.token = token;
    let header = {};
    if (setHeader && this.token) {
      header = { headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('Authorization', 'Bearer ' + (this.token).toString())
          .set('id', (adminID).toString())
      };
    }
    if (params) {
      const param = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      url = url + '?' + param;
    }
    return this.http.get(url, header).pipe(
			tap( // Log the result or error
				{
					next: (data) => this.log(data),
					error: (error) => this.logError(error)
				}
			)
		);
  }

  public postParams(url, params = null, setHeader = false) {
    this.astroData = this.common.getAstroData();
    this.token = this.common.getToken();
    let header = {};
    if (setHeader) {
      header = { headers: new HttpHeaders()
          .set('Authorization', 'Bearer ' + (this.token).toString())
          .set('id', (this.astroData ? this.astroData.id : '').toString())
      };
    }
    const formData = new FormData();
    if (params) {
      for (const param in params) {
        formData.append(param, params[param]);
      }
    }
    return this.http.post(url, formData, header).pipe(
			tap( // Log the result or error
				{
					next: (data) => this.log(data),
					error: (error) => this.logError(error)
				}
			)
		);
  }

  public postJson(url, params = null, setHeader = false) {
    this.astroData = this.common.getAstroData();
    this.token = this.common.getToken();
    let header = {};
    if (setHeader) {
      header = { headers: new HttpHeaders()
          .set('Authorization', 'Bearer ' + (this.token).toString())
          .set('id', (this.astroData ? this.astroData.id : '').toString())
          .set('content_type', 'Application/Json')
      };
    }
    return this.http.post(url, params, header).pipe(
			tap( // Log the result or error
				{
					next: (data) => this.log(data),
					error: (error) => this.logError(error)
				}
			)
		);
  }

  public post(url, body, httpHeader = false) {
    this.astroData = this.common.getAstroData();
    this.token = this.common.getToken();
    let header = {};
    if (httpHeader) {
      header = { headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + (this.token).toString())
        .set('id', (this.astroData ? this.astroData.id : '').toString())
      };
    }
    return this.http.post(url, body, header).pipe(
			tap( // Log the result or error
				{
					next: (data) => this.log(data),
					error: (error) => this.logError(error)
				}
			)
		);
  }

  public postHeaderParams(url, params = null, setHeader = false) {
    this.astroData = this.common.getAstroData();
    this.token = this.common.getToken();
    let header = {};
    if (setHeader) {
      header = { headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + (this.token).toString())
        .set('id', (this.astroData ? this.astroData.id : '').toString())
      };
    }
    if (params) {
      const param = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      url = url + '?' + param;
    }
    return this.http.post(url, null, header).pipe(
			tap( // Log the result or error
				{
					next: (data) => this.log(data),
					error: (error) => this.logError(error)
				}
			)
		);
  }

  public postHeaderParamsWithCandidateID(url, params = null, setHeader = false, id) {
    let header = {};
    if (setHeader) {
      header = { headers: new HttpHeaders()
        .set('id', (id ? id : '').toString())
      };
    }
    if (params) {
      const param = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      url = url + '?' + param;
    }
    return this.http.post(url, null, header).pipe(
			tap( // Log the result or error
				{
					next: (data) => this.log(data),
					error: (error) => this.logError(error)
				}
			)
		);
  }

  public postHeaderParamsAndBody(url, params = null, body, setHeader = false) {
    this.astroData = this.common.getAstroData();
    this.token = this.common.getToken();
    let header = {};
    if (setHeader) {
      header = { headers: new HttpHeaders()
          .set('Authorization', 'Bearer ' + (this.token).toString())
          .set('id', (this.astroData ? this.astroData.id : '').toString())
      };
    }
    if (params) {
      const param = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      url = url + '?' + param;
    }
    return this.http.post(url, body, header).pipe(
			tap( // Log the result or error
				{
					next: (data) => this.log(data),
					error: (error) => this.logError(error)
				}
			)
		);
  }

  public putHeaderParamsAndBody(url, params = null, body, setHeader = false) {
    this.astroData = this.common.getAstroData();
    this.token = this.common.getToken();
    let header = {};
    if (setHeader) {
      header = new HttpHeaders()
        .set('Authorization', 'Bearer ' + (this.token).toString())
        .set('id', (this.astroData ? this.astroData.id : '').toString());
    }
    if (params) {
      const param = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      url = url + '?' + param;
    }
    return this.http.request('put', url, {headers: header, body: body}).pipe(
			tap( // Log the result or error
				{
					next: (data) => this.log(data),
					error: (error) => this.logError(error)
				}
			)
		);
  }

  public putParamRequest(url, params = null, setHeader = false) {
    this.astroData = this.common.getAstroData();
    this.token = this.common.getToken();
    let header = {};
    if (setHeader) {
      header = new HttpHeaders()
        .set('Authorization', 'Bearer ' + (this.token).toString())
        .set('id', (this.astroData ? this.astroData.id : '').toString());
    }
    if (params) {
      const param = Object.keys(params).map(key => key + '=' + encodeURIComponent(params[key])).join('&');
      url = url + '?' + param;
    }
    return this.http.request('put', url, {headers: header}).pipe(
			tap( // Log the result or error
				{
					next: (data) => this.log(data),
					error: (error) => this.logError(error)
				}
			)
		);
  }

	public putParamRequestForAdmin(url, params = null, setHeader = false, token, adminID) {
		let header = {};
		if (setHeader && token) {
			header = { headers: new HttpHeaders()
					.set('Content-Type', 'application/json')
					.set('Authorization', 'Bearer ' + (token).toString())
					.set('id', (adminID).toString())
			};
		}
		if (params) {
			const param = Object.keys(params).map(key => key + '=' + encodeURIComponent(params[key])).join('&');
			url = url + '?' + param;
		}
		return this.http.request('put', url, header).pipe(
			tap( // Log the result or error
				{
					next: (data) => this.log(data),
					error: (error) => this.logError(error)
				}
			)
		);
	}

	public putParamRequestForUsers(url, params = null, setHeader = false, ID) {
		let header = {};
		if (setHeader && ID) {
			header = { headers: new HttpHeaders()
					.set('Content-Type', 'application/json')
					.set('id', (ID).toString())
			};
		}
		if (params) {
			const param = Object.keys(params).map(key => key + '=' + encodeURIComponent(params[key])).join('&');
			url = url + '?' + param;
		}
		return this.http.request('put', url, header).pipe(
			tap( // Log the result or error
				{
					next: (data) => this.log(data),
					error: (error) => this.logError(error)
				}
			)
		);
	}

	public putParamRequestWithPassword(url, params = null, setHeader = false, password = '', adminID = '') {
		let header = {};
		if (setHeader) {
			header = new HttpHeaders()
				.set('Content-Type', 'application/json')
				.set('token', password)
				.set('id', (adminID).toString())
		}
		if (params) {
			const param = Object.keys(params).map(key => key + '=' + params[key]).join('&');
			url = url + '?' + param;
		}
		return this.http.request('put', url, {headers: header}).pipe(
			tap( // Log the result or error
				{
					next: (data) => this.log(data),
					error: (error) => this.logError(error)
				}
			)
		);
	}

  public delete(url, params = null, setHeader = false) {
    this.astroData = this.common.getAstroData();
    this.token = this.common.getToken();
    let header = {};
    if (setHeader) {
      header = new HttpHeaders()
        .set('Authorization', 'Bearer ' + (this.token).toString())
        .set('id', (this.astroData ? this.astroData.id : '').toString());
    }

    const formData = new FormData();
    if (params) {
      for (const param in params) {
        formData.append(param, params[param]);
      }
    }
    return this.http.request('delete', url, {headers: header, body : formData}).pipe(
			tap( // Log the result or error
				{
					next: (data) => this.log(data),
					error: (error) => this.logError(error)
				}
			)
		);
  }

  public deleteParams(url, params = null, setHeader = false) {
    this.astroData = this.common.getAstroData();
    this.token = this.common.getToken();
    let header = {};
    if (setHeader) {
      header = new HttpHeaders()
        .set('Authorization', 'Bearer ' + (this.token).toString())
        .set('id', (this.astroData ? this.astroData.id : '').toString());
    }

    if (params) {
      const param = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      url = url + '?' + param;
    }
    return this.http.request('delete', url, {headers: header}).pipe(
			tap( // Log the result or error
				{
					next: (data) => this.log(data),
					error: (error) => this.logError(error)
				}
			)
		);
  }

  public deleteQueryParam(url, params = null, setHeader = false) {
    this.astroData = this.common.getAstroData();
    this.token = this.common.getToken();
    let header = {};
    if (setHeader) {
      header = new HttpHeaders()
        .set('Authorization', 'Bearer ' + (this.token).toString())
        .set('id', (this.astroData ? this.astroData.id : '').toString());
    }

    if (params) {
      const param = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      url = url + '?' + param;
    }
    return this.http.request('delete', url, {headers: header}).pipe(
			tap( // Log the result or error
				{
					next: (data) => this.log(data),
					error: (error) => this.logError(error)
				}
			)
		);
  }

	public postJsonAdminToken(url, params = null, setHeader = false, token, adminID) {
		this.astroData = this.common.getAstroData();
		this.token = token;
		let header = {};
		if (setHeader) {
			header = { headers: new HttpHeaders()
					.set('Authorization', 'Bearer ' + (this.token).toString())
					.set('id', adminID.toString())
					.set('content_type', 'Application/Json')
			};
		}
		return this.http.post(url, params, header).pipe(
			tap( // Log the result or error
				{
					next: (data) => this.log(data),
					error: (error) => this.logError(error)
				}
			)
		);
	}

	public verifyToken( setHeader = false) {
		let url = AppConstant.apiUrl + AppConstant.tokenExpired;
		let params = {userId: this.common.getAstroData('id')};
		this.astroData = this.common.getAstroData();
		this.token = this.common.getToken();
		let header = {};
		if (setHeader) {
			header = { headers: new HttpHeaders()
					.set('Authorization', 'Bearer ' + (this.token).toString())
					.set('id', (this.astroData ? this.astroData.id : '').toString())
			};
		}
		if (params) {
			const param = Object.keys(params).map(key => key + '=' + params[key]).join('&');
			url = url + '?' + param;
		}
		return this.http.post(url, null, header).pipe(
			tap( // Log the result or error
				{
					next: (data) => this.log(data),
					error: (error) => this.logError(error)
				}
			)
		);
	}
}
