import { Injectable } from '@angular/core';
import {HttpWrapperService} from '../../core/services/http-wrapper.service';
import {AppConstant} from '../../core/services/constant';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private chatHistory = AppConstant.chatApi + AppConstant.chatHistory;
  private chatAccept = AppConstant.chatApi + AppConstant.chatAccept;
  private chatReject = AppConstant.chatApi + AppConstant.chatReject;
  private chatStatusApi = AppConstant.chatApi + AppConstant.chatStatusApi;
  private saveNotes = AppConstant.apiUrl + AppConstant.saveNotes;
  private getNotes = AppConstant.apiUrl + AppConstant.getNotes;
  private callChatRefund = AppConstant.apiUrl + AppConstant.callChatRefund;
  private checkBlockStatusApi = AppConstant.supportApiUrl + AppConstant.checkBlockStatus;
  private checkConsultantEarningApi = AppConstant.apiUrl + AppConstant.checkConsultantEarningApi;
  private blockUserApi = AppConstant.apiUrl + AppConstant.blockUserApi;
  private createBlockTicketApi = AppConstant.supportApiUrl + AppConstant.createBlockTicket;
  private sendBlockMessageApi = AppConstant.supportApiUrl + AppConstant.sendBlockMessage;
  private getAutoMsg = AppConstant.supportApiUrl + AppConstant.getAutoMsg;
  private saveAndUpdateAutoMsg = AppConstant.supportApiUrl + AppConstant.saveAndUpdateAutoMsg;
  private deleteAutoMsg = AppConstant.supportApiUrl + AppConstant.deleteAutoMsg;
  private sendLocation = AppConstant.apiUrl + AppConstant.sendLocation;

  constructor(private http: HttpWrapperService) { }

  getChatHistory = (params): any => {
    return this.http.get(this.chatHistory, params, true);
  }

  acceptChat = (params): any => {
    return this.http.postHeaderParams(this.chatAccept, params, true);
  }

  rejectChat = (params): any => {
    return this.http.postHeaderParams(this.chatReject, params, true);
  }

  checkChatStatus = (params) => {
    return this.http.get(this.chatStatusApi, params, true);
  }

  getNote = (params) => {
    return this.http.get(this.getNotes, params, true);
  }

  saveNote = (params, body) => {
    return this.http.postHeaderParamsAndBody(this.saveNotes, params, body, true);
  }

  refundCallChatAmount = (params): any => {
    return this.http.postHeaderParams(this.callChatRefund, params, true);
  }

  checkUserBlockStatus = (params): any => {
    return this.http.postHeaderParams(this.checkBlockStatusApi, params, true);
  }

  checkConsultantEarning = (params): any => {
    return this.http.get(this.checkConsultantEarningApi, params, true);
  }

  blockUser = (params): any => {
    return this.http.postParams(this.blockUserApi, params, true);
  }

  createBlockTicket = (params): any => {
    return this.http.postJson(this.createBlockTicketApi, params, true);
  }

  sendBlockMessage = (params): any => {
    return this.http.postJson(this.sendBlockMessageApi, params, true);
  }

  getAutomatedMessages = (params): any => {
    return this.http.get(this.getAutoMsg, params, true);
  }

  saveUpdatedAutomatedMessage = (params): any => {
    return this.http.postParams(this.saveAndUpdateAutoMsg, params, true);
  }

  deleteAutomatedMessage = (params): any => {
    return this.http.postParams(this.deleteAutoMsg, params, true);
  }

  sendLocationToBackEnd = (params): any => {
    return this.http.postParams(this.sendLocation, params, true);
  }
}
