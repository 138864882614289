import {Injectable} from '@angular/core';
import {AppConstant} from '../../core/services/constant';
import {HttpWrapperService} from '../../core/services/http-wrapper.service';

@Injectable({
	providedIn: 'root'
})
export class VideoCallService {
	private videoCallStatusApi = AppConstant.apiUrl + AppConstant.videoCallStatusApi;
	private videoAcceptApi = AppConstant.apiUrl + AppConstant.videoAcceptApi;
	private videoRejectApi = AppConstant.apiUrl + AppConstant.videoRejectApi;
	private videoMuteApi = AppConstant.apiUrl + AppConstant.videoMuteApi;
	private audioMuteApi = AppConstant.apiUrl + AppConstant.audioMuteApi;
	private videoCallEndApi = AppConstant.apiUrl + AppConstant.videoCallEndApi;
	private callHistoryDetails = AppConstant.apiUrl + AppConstant.callHistoryDetails;

	constructor(private http: HttpWrapperService) {
	}

	getCallHistoryDetails = (params): any => {
		return this.http.get(this.callHistoryDetails, params, true);
	}

	checkVideoCallStatus = (params) => {
		return this.http.get(this.videoCallStatusApi, params, true);
	}

	acceptVideoCall = (params): any => {
		return this.http.postHeaderParams(this.videoAcceptApi, params, true);
	}

	rejectVIdeoCall = (params): any => {
		return this.http.postHeaderParams(this.videoRejectApi, params, true);
	}

	muteUnmuteVideo = (params): any => {
		return this.http.postHeaderParams(this.videoMuteApi, params, true);
	}

	muteUnmuteAudio = (params): any => {
		return this.http.postHeaderParams(this.audioMuteApi, params, true);
	}

	endCall = (params): any => {
		return this.http.postHeaderParams(this.videoCallEndApi, params, true);
	}
}
