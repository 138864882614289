import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {CoreModule} from './core/core.module';
import {RouterModule} from '@angular/router';
import {CommonService} from './core/services/common.service';
import {HttpWrapperService} from './core/services/http-wrapper.service';
import {GlobalModule} from './module/global/global.module';
import {HeaderComponent} from './module/global/header/header.component';
import {ToastrModule} from 'ngx-toastr';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {WindowService} from './core/services/window.service';
import {environment} from '../environments/environment';
import {AngularFireModule} from '@angular/fire';
import {MessagingService} from './core/services/messaging.service';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CommonModule} from '@angular/common';

@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		AngularFireModule.initializeApp(environment.firebase, 'firebase'),
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		HttpClientModule,
		CoreModule,
		GlobalModule,
		ToastrModule.forRoot({
			timeOut: 10000,
			positionClass: 'toast-bottom-right',
			preventDuplicates: false,
			progressBar: true
		}),
		NgbModule,
		MatDialogModule,
		MatButtonModule,
		MatTooltipModule,
		CommonModule
	],
	providers: [
		CommonService,
		HttpWrapperService,
		MessagingService,
		WindowService
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
