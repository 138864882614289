import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonService} from '../../../core/services/common.service';
import {AuthenticateService} from '../../../core/services/authenticate.service';
import {Router} from '@angular/router';
import {WindowService} from '../../../core/services/window.service';
import {MessagingService} from '../../../core/services/messaging.service';
import {RouterChangeService} from '../../../core/services/router-change.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public isMobile = false;
	isOnlyForSupport: boolean = false;
  isLogin = false;
  astroData: any = [];
  isLoginSubscribe;
  isForeign = false;
  currentUrl: string;
  isHeaderShadow = true;
  browserTimeZone;
  windowRef: any;
  activeUrl;
  urlIncludes;
  showPermission = false;
	url = '';
	showALL = false;

  constructor(private common: CommonService,
              private router: Router,
              private routerChangeService: RouterChangeService,
              private windowService: WindowService,
              private messagingService: MessagingService,
              private authService: AuthenticateService
  ) {
    this.routerChangeService.activeRoute.subscribe((e) => {
      this.activeUrl = e.url;
      this.urlIncludes = this.activeUrl;
      this.urlIncludes = window.location.pathname.split('/').slice(0).join('/');
			var link = document.location.href.split('/');
			this.url = link[3];
    });

  }

  ngOnInit(): void {
		this.isMobile = this.common.checkDeviceIsMobile();
    this.browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if ('permissions' in navigator) {
      navigator.permissions.query({name: 'notifications'})
        .then((permission) => {
          this.showPermission = permission.state != 'granted';
          permission.onchange = () => {
            this.showPermission = permission.state != 'granted';
          };
        });
    }
    document.addEventListener('DOMContentLoaded', function() {
      if (!Notification) {
        alert('Desktop notifications not available in your browser. Try Chromium.');
        return;
      }

      if (Notification.permission !== 'granted') {
        Notification.requestPermission();
      }
    });

    if (this.browserTimeZone == 'Asia/Calcutta') {
      this.browserTimeZone = 'Asia/Kolkata';
    }
    this.windowRef = this.windowService.windowRef;
    if (this.currentUrl == '') {
      this.isHeaderShadow = false;
    }
    this.isLoginSubscribe = this.common.getLoginValue().subscribe((value) => {
      this.isLogin = value;
      if (this.isLogin) {
				const userType = this .common.getAstroData('consultantSubType');
				if (userType && userType == 'PAYROLL') {
					this.showALL = false
				} else {
					this.showALL = true;
				}
        this.messagingService.allowNotification();
        this.astroData = this.common.getAstroData();
        this.getTimeZoneByApi();
        this.isForeign = (this.common.timeZone() != 'Asia/Kolkata');
				const isDeleted = this.common.getItem('isDeleted');
				const isOpenForSupportWithSdOff = this.common.getItem('isOpenForSupportWithSdOff');
				if(isDeleted == 'true' && isOpenForSupportWithSdOff == 'true') {
					this.isOnlyForSupport = true;
				}
				this.getLanguageData();
			} else {
        if (!this.common.getAstroData('isForeigner')) {
          this.common.setLocalTimeZone();
        }
        this.isForeign = (this.common.timeZone() != 'Asia/Kolkata');
      }
    });
  }

  async getTimeZoneByApi() {
    const isForeign = this.common.getAstroData('isForeigner');
    await this.common.setApiTimeZone(isForeign);
  }

  logout() {
    this.common.logout();
    this.astroData = null;
    this.common.setLoginValue(false);
    this.router.navigate(['/']);
  }

  getIp() {
    if (!this.common.getIp()) {
      this.authService.ipAddress().subscribe((response: any) => {
        this.common.setIp(response?.ip);
      });
    }
  }

  ngOnDestroy() {
    if (this.isLoginSubscribe) {
      this.isLoginSubscribe.unsubscribe();
    }
  }

	languages;
	getLanguageData() {
		const paramData = {
			appId: this.common.getAppId(),
			businessId: this.common.getBusinessId()
		};
		this.authService.getLanguageNote(paramData).subscribe((res: any) => {
			if (res.status == 'success') {
				this.languages = res.languages
			}
		});
	}
}
