import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {CommonService} from '../services/common.service';

@Injectable()
export class NoAuthGuardService implements CanActivate{

  constructor(private router: Router, public common: CommonService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const user = !!this.common.getItem(environment.authKey);
    if (!user) {
      return true;
    } else {
			const isDeleted = this.common.getItem('isDeleted');
			const isOpenForSupportWithSdOff = this.common.getItem('isOpenForSupportWithSdOff');
			if(isDeleted == 'true' && isOpenForSupportWithSdOff == 'true') {
				this.router.navigate(['/support-chat']);
			}else{
				this.router.navigate(['/dashboard']);
			}
      return false;
    }
  }
}
